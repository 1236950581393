@import "reset";

@font-face {
	font-family: MassimoSemi;
	src: url("https://cdngarenanow-a.akamaihd.net/gstaticid/AOV/fonts/Massimo  SemiBold.otf");
}
@font-face {
	font-family: MassimoBold;
	src: url("https://cdngarenanow-a.akamaihd.net/gstaticid/AOV/fonts/Massimo Bold.otf");
}
@font-face {
	font-family: RobotoMedium;
	src: url("https://cdngarenanow-a.akamaihd.net/gstaticid/AOV/fonts/Roboto-Medium.ttf");
}
@font-face {
	font-family: RobotoBlack;
	src: url("https://cdngarenanow-a.akamaihd.net/gstaticid/AOV/fonts/Roboto-Black.ttf");
}

@media only screen and (orientation: portrait) {
	html {
		transform: rotate(90deg);
		transform-origin: 50vmin 50vmin;
		width: 100vmax !important;
	}
}

/* Modal layout setup */
.react-responsive-modal-root {
	display: flex;
	align-items: center;
}

.react-responsive-modal-overlay {
	overflow: hidden;
	padding: 0 !important;
}

.react-responsive-modal-container {
	width: 13.34rem !important;
	height: 6.6rem !important;
	display: flex;
	align-items: center;
	margin: 0 auto;
	font-size: 0.36rem;
}

.react-responsive-modal-modal {
	margin: auto !important;
	max-width: none !important;
}

#root {
	position: absolute;
	width: 100%;
	height: 100%;
	font-size: 0.36rem;
}

#Main {
	position: fixed;
	margin: auto;
	top: 0 !important;
	right: 0;
	left: 0;
	bottom: 0;
	/* setup layout size */
	width: 13.34rem;
	height: 6.6rem;

	&:before {
		content: "";
		position: fixed;
		z-index: -2;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: #000;
		/* Setup background for handle space on left and right or top and bottom. */
		/*
          background-image: var(--background);
          background-size: 13.34rem auto;
          background-position: center 50%;
          background-repeat: no-repeat;
      */
	}

	.content-container {
		width: 100%;
		height: 100%;
		position: relative;
	}
}
