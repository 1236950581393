.customModal {
	max-width: none;
	width: 7.26rem;
	height: 4.82rem;
	background: url("https://cdngarenanow-a.akamaihd.net/gstaticid/AOV/supersale3/popup_bg.jpg")
		center/contain no-repeat;
	padding: 0;
	outline: none;

	.header {
		height: 12%;
		position: relative;
		display: grid;
		place-items: center;

		> span {
			color: #fff;
			font-family: RobotoMedium;
			font-size: 0.3rem;
		}

		> img {
			max-height: 55%;
			position: absolute;
			top: 0;
			right: 3%;
			bottom: 0;
			margin: auto;
			cursor: pointer;
		}
	}
}
